import React from 'react'

import styles from "./Styles/Footer.module.css"

const Footer = () => {
  return (
    <div className={styles.Footer}>
        
    </div>
  )
}

export default Footer